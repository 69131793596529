import Image from "../../../../src/components/imageComponent.js";
import * as priceSectionStyles from "../../../../src/components/priceSection.module.css";
import * as servicePageStyles from "../../../../src/templates/servicePage.module.css";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  Image,
  priceSectionStyles,
  servicePageStyles,
  Link,
  React
};