// extracted by mini-css-extract-plugin
export var aboutContainer = "servicePage-module--aboutContainer--Sl8NR";
export var aboutText = "servicePage-module--aboutText--ddPKu";
export var beforeAfterCard = "servicePage-module--beforeAfterCard--dMGU+";
export var beforeAfterGrid = "servicePage-module--beforeAfterGrid--s36E+";
export var beforeAfterImg = "servicePage-module--beforeAfterImg--Ty6+V";
export var beforeAfterTitle = "servicePage-module--beforeAfterTitle--9kcp5";
export var carIcon = "servicePage-module--carIcon--1gBR0";
export var carLogo = "servicePage-module--carLogo--HMMrr";
export var carLogoCard = "servicePage-module--carLogoCard--Z00Et";
export var carLogoGrid = "servicePage-module--carLogoGrid--U1j36";
export var container = "servicePage-module--container--+Lz0P";
export var containerGrid = "servicePage-module--containerGrid--OcYzm";
export var faqAnswer = "servicePage-module--faqAnswer--tYdG6";
export var faqContainer = "servicePage-module--faqContainer--rRg1B";
export var faqQuestion = "servicePage-module--faqQuestion--Dtbz1";
export var featureText = "servicePage-module--featureText--Xi89e";
export var featureTitle = "servicePage-module--featureTitle--NKENb";
export var mobileDevice = "servicePage-module--mobileDevice--Kevhq";
export var mobileDeviceImg = "servicePage-module--mobileDeviceImg--1BH7S";
export var mobileDeviceImgGrid = "servicePage-module--mobileDeviceImgGrid--3WlQs";
export var noProjectMessage = "servicePage-module--noProjectMessage--5mtL2";
export var openFaqBtn = "servicePage-module--openFaqBtn--VfLe9";
export var openFeatureBtn = "servicePage-module--openFeatureBtn--PoUFi";
export var portfolioParagraph = "servicePage-module--portfolioParagraph--OOj-V";
export var priceCards = "servicePage-module--priceCards--jjK7L";
export var projectsGrid = "servicePage-module--projectsGrid--nscpS";
export var serviceImg = "servicePage-module--serviceImg--VHzSg";
export var tintGrid = "servicePage-module--tintGrid--jktvL";
export var tintTypeCard = "servicePage-module--tintTypeCard--Ah9yv";
export var tintTypeImg = "servicePage-module--tintTypeImg--yxVP1";
export var videoGrid = "servicePage-module--videoGrid--mCyQU";