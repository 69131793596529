import React, { useState } from "react"
import * as photoSelectorStyles from "./photoSelector.module.css"
import Image from "./imageComponent.js"

import "animate.css"

class PhotoSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = { imageNumber: 0 }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {}

  handleClick(number) {
    const prevButton = document.getElementsByClassName(
      photoSelectorStyles.button
    )[this.state.imageNumber]
    const newButton = document.getElementsByClassName(
      photoSelectorStyles.button
    )[number]
    this.setState({
      imageNumber: number,
    })
    prevButton.classList.remove(photoSelectorStyles.button__active)
    newButton.classList.add(photoSelectorStyles.button__active)
  }

  render() {
    let imageFileName = this.props.name + this.state.imageNumber + ".png"

    return (
      <div className={photoSelectorStyles.selectorDiv}>
        <div>
          <Image alt="" fileName={this.props.background} ariaHidden={true} />
          <Image
            alt={this.props.alt}
            fileName={imageFileName}
            imgClass={photoSelectorStyles.imgOverlay}
          />
        </div>
        <div className={photoSelectorStyles.buttonGrid}>
          <button
            className={
              photoSelectorStyles.button +
              " " +
              photoSelectorStyles.button__active
            }
            onClick={() => {
              this.handleClick(0)
            }}
          >
            {this.props.firstButton}
          </button>
          {this.props.buttons.map((value, index) => {
            return (
              <button
                className={photoSelectorStyles.button}
                key={index}
                onClick={() => {
                  this.handleClick(index + 1)
                }}
              >
                {value}
              </button>
            )
          })}
        </div>
        <p className={photoSelectorStyles.text}>
          (Select package to preview, all prices shown in section below)
        </p>
      </div>
    )
  }
}

export default PhotoSelector
