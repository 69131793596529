import React from "react"

export default class FeatureListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { textOpen: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState(state => ({
      textOpen: !state.textOpen,
    }))
  }

  render() {
    return (
      <li>
        <span
          className={this.props.styles.featureTitle}
          onClick={this.handleClick}
          style={
            this.state.textOpen ? { fontWeight: 600 } : { fontWeight: 400 }
          }
        >
          {this.props.title}
          {this.state.textOpen ? (
            <span className={this.props.styles.openFeatureBtn}>-</span>
          ) : (
            <span className={this.props.styles.openFeatureBtn}>+ </span>
          )}
        </span>
        {this.state.textOpen && (
          <p className={this.props.styles.featureText}>{this.props.children}</p>
        )}
      </li>
    )
  }
}
