import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

const Image = ({ fileName, alt, imgClass, animated, ariaHidden }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 1440, quality: 100) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const fluid = allImageSharp.nodes.find(n => n.fluid.originalName === fileName)
    .fluid

  if (animated === false) {
    return (
      <Img
        fluid={fluid}
        alt={alt}
        className={imgClass}
        fadeIn={false}
        loading="lazy"
        draggable={false}
        aria-hidden={ariaHidden}
      />
    )
  } else {
    return (
      <ScrollAnimation
        animateIn="animate__fadeIn"
        animateOnce={true}
        duration={0.5}
        className={imgClass}
      >
        <Img
          fluid={fluid}
          alt={alt}
          fadeIn={false}
          loading="lazy"
          draggable={false}
          className={imgClass}
          aria-hidden={ariaHidden}
        />
      </ScrollAnimation>
    )
  }
}

Image.defaultProps = {
  ariaHidden: false,
}

export default Image
