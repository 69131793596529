// extracted by mini-css-extract-plugin
export var carSizeContainer = "priceSection-module--carSizeContainer--39aMN";
export var carSizeHeading = "priceSection-module--carSizeHeading--i4vY+";
export var closeTableBtn = "priceSection-module--closeTableBtn--Bu8po";
export var container = "priceSection-module--container--b0M-E";
export var headerExamples = "priceSection-module--headerExamples--bvh+-";
export var notes = "priceSection-module--notes--NM0m2";
export var openTableBtn = "priceSection-module--openTableBtn--Zq74m";
export var priceBasedOn = "priceSection-module--priceBasedOn--mpZXJ";
export var priceCard = "priceSection-module--priceCard--vSAkp";
export var priceCardContainer = "priceSection-module--priceCardContainer--IHWEa";
export var table = "priceSection-module--table--xJuCN";
export var tableContainer = "priceSection-module--tableContainer--dcv+g";
export var tablePackageDescription = "priceSection-module--tablePackageDescription--uh79F";
export var tablePrice = "priceSection-module--tablePrice--f11cc";
export var tablePriceAsterix = "priceSection-module--tablePriceAsterix--GO+xs";
export var tablePriceContainer = "priceSection-module--tablePriceContainer--fgCeN";
export var tablePriceFrom = "priceSection-module--tablePriceFrom--hGh3D";
export var tablePriceOnRequest = "priceSection-module--tablePriceOnRequest--TeuKo";
export var tablePriceVAT = "priceSection-module--tablePriceVAT--rO9fz";